.btn {
  --bs-btn-border-radius: 0;
}

.form-control,
.form-select,
.input-group-text,
.form-check:not(.form-switch) .form-check-input[type=checkbox] {
  border-radius: 0;
}

.main-logo-text {
  font-family: "Segoe UI", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
}

.layout-menu-toggle i {
  -webkit-animation: spin 0.5s;
  -moz-animation: spin 0.5s;
  animation: spin 0.5s;
  rotate: 0deg;
}

@-moz-keyframes spin {
  0% {
    -moz-transform: rotate(180deg);
  }

  100% {
    -moz-transform: rotate(360deg);
  }
}

@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(180deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  0% {
    -webkit-transform: rotate(180deg);
    transform: rotate(180deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

.layout-menu-hover .layout-menu-toggle i {
  -webkit-animation: unset;
  -moz-animation: unset;
  animation: unset;
  rotate: 180deg;
}

.pb-1px {
  padding-bottom: 1px !important;
}

.avatar-by-name {
  background-color: var(--secondary-color);
  width: 100% !important;
  height: 100% !important;
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--bs-white);
  text-transform: uppercase;
  font-weight: 700;
}

.bg-menu-theme .menu-inner>.menu-item.active>.menu-link {
  margin: 0rem 1rem 0rem 0rem;
  padding-left: 2rem;
  border-radius: 0 0.375rem 0.375rem 0rem !important;
  background-color: var(--secondary-color) !important;
  color: var(--bs-white) !important;
}

.menu:not(.menu-no-animation) .menu-link {
  transition-duration: unset;
  transition-property: unset;
}

.layout-navbar.navbar-detached {
  border-radius: var(--bs-navbar-toggler-border-radius);
}

.navbar {
  --bs-navbar-toggler-border-radius: 0;
}

.dropdown-menu {
  --bs-dropdown-border-radius: 0;
  --bs-dropdown-inner-border-radius: 0;
}

.card {
  --bs-card-border-radius: 0;
  --bs-card-inner-border-radius: 0;
}

html:not(.layout-menu-collapsed) .bg-menu-theme .menu-inner .menu-item.open>.menu-link,
html:not(.layout-menu-collapsed) .bg-menu-theme .menu-inner .menu-item.active>.menu-link.menu-toggle,
.layout-menu-hover.layout-menu-collapsed .bg-menu-theme .menu-inner .menu-item.open>.menu-link,
html:not(.layout-menu-collapsed) .bg-menu-theme .menu-inner .menu-item .menu-link:not(.active):hover,
.layout-menu-hover.layout-menu-collapsed .bg-menu-theme .menu-inner .menu-item .menu-link:not(.active):hover {
  margin: 0rem 1rem 0rem 0rem;
  padding: .625rem 1rem .625rem 2rem;
  border-radius: 0 0.375rem 0.375rem 0rem !important;
}

html:not(.layout-menu-collapsed) .bg-menu-theme .menu-inner .menu-item.open .menu-link:not(.menu-toggle):not(.active):hover {
  margin: 0rem 1rem;
  padding-left: 3rem;
  border-radius: 0.375rem;
}

.app-brand-text.demo {
  text-transform: uppercase;
}

.logo-app {
  max-width: 30px;
  max-height: 30px;
  border-radius: 3px;
  margin-right: 10px;
}

html:not(.layout-menu-collapsed) .bg-menu-theme .menu-inner .menu-item.open>.menu-link,
.layout-menu-hover.layout-menu-collapsed .bg-menu-theme .menu-inner .menu-item.open>.menu-link,
html:not(.layout-menu-collapsed) .bg-menu-theme .menu-inner .menu-item .menu-link:not(.active):hover,
.layout-menu-hover.layout-menu-collapsed .bg-menu-theme .menu-inner .menu-item .menu-link:not(.active):hover {
  background-color: var(--secondary-transparent-color);
}

@media (min-width: 1200px) {
  .layout-menu-collapsed:not(.layout-menu-hover):not(.layout-menu-offcanvas):not(.layout-menu-fixed-offcanvas) .layout-menu.menu-vertical .menu-inner>.menu-item.active>.menu-link {
    padding-left: 2rem;
  }
}

.table .table-primary {
  --bs-table-bg: var(--primary-color);
  --bs-table-striped-bg: var(--primary-color);
  --bs-table-striped-color: var(--primary-color);
  --bs-table-active-bg: var(--primary-color);
  --bs-table-active-color: var(--primary-color);
  --bs-table-hover-bg: var(--primary-color);
  --bs-table-hover-color: var(--primary-color);
}

.table .table-primary th {
  padding: 0.825rem 1.25rem;
  color: var(--bs-white);
}

.pagination {
  --bs-pagination-color: var(--primary-color);
  --bs-pagination-bg: var(--secondary-color);
  --bs-pagination-border-width: 0px;
  --bs-pagination-border-color: var(--secondary-color);
  --bs-pagination-hover-color: var(--primary-color);
  --bs-pagination-hover-bg: #ebeef5;
  --bs-pagination-hover-border-color: rgba(67, 89, 113, 0.3);
  --bs-pagination-focus-color: var(--primary-color);
  --bs-pagination-focus-bg: #ebeef5;
}

.cari.input-group .form-control,
.cari.input-group .input-group-text {
  border-color: rgba(161, 172, 184, .45);
}

.cari.input-group:focus-within .form-control,
.cari.input-group:focus-within .input-group-text {
  border-color: var(--secondary-color);
}

.btn-secondary {
  background-color: var(--secondary-color);
  border-color: var(--secondary-color);
  color: var(--bs-white);
}

.btn-check:checked+.btn-secondary,
.btn-check:active+.btn-secondary,
.btn-secondary:active,
.btn-secondary.active,
.btn-secondary.show.dropdown-toggle,
.show>.btn-secondary.dropdown-toggle,
.btn-secondary:hover {
  background-color: #ebeef5 !important;
  border-color: #ebeef5 !important;
  color: var(--primary-color) !important;
}

@media all and (max-width:480px) {
  .btn-md-block {
    width: 100%;
    display: block;
  }
}

.react-datepicker-wrapper {
  display: block;
}

.react-datepicker {
  font-size: 0.9375rem;
  color: #697a8d;
  text-align: left;
  list-style: none;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid transparent;
  border-radius: 0;
  box-shadow: 0 0.25rem 1rem rgba(161, 172, 184, .45);
  animation: dropdownAnimation .1s;
  font-family: var(--bs-body-font-family);
}

.react-datepicker__triangle {
  display: none;
}

.react-datepicker__header {
  background-color: unset;
  border-bottom: 1px solid #d9dee3;
}

.react-datepicker__day-names {
  border-top: 1px solid #d9dee3;
}

.react-datepicker__header__dropdown {
  border-top: 1px solid #d9dee3;
  padding: 0.25rem 0.5rem;
}

.react-datepicker__month-dropdown-container {
  margin: 0 0.2rem;
}

.react-datepicker__year-dropdown-container {
  margin: 0 1rem;
}

.react-datepicker__current-month,
.react-datepicker-time__header,
.react-datepicker-year-header {
  margin-bottom: 0.5rem;
  color: var(--primary-color);
  font-weight: 500;
  cursor: pointer;
  font-family: var(--bs-body-font-family);
}

.react-datepicker__day-name,
.react-datepicker__day,
.react-datepicker__time-name {
  color: inherit;
  display: inline-block;
  width: 1.7rem;
  line-height: 1rem;
  text-align: center;
  font-size: .8125rem;
  font-weight: normal;
  padding-top: 0.6rem;
  margin: 0.166rem;
  height: 2.05rem;
  text-align: center;
  border: none;
}

.react-datepicker__day--selected,
.react-datepicker__day--in-selecting-range,
.react-datepicker__day--in-range,
.react-datepicker__month-text--selected,
.react-datepicker__month-text--in-selecting-range,
.react-datepicker__month-text--in-range,
.react-datepicker__quarter-text--selected,
.react-datepicker__quarter-text--in-selecting-range,
.react-datepicker__quarter-text--in-range,
.react-datepicker__year-text--selected,
.react-datepicker__year-text--in-selecting-range,
.react-datepicker__year-text--in-range {
  border-radius: 0;
  background-color: var(--primary-color);
  color: #fff;
}

.react-datepicker__day:hover,
.react-datepicker__month-text:hover,
.react-datepicker__quarter-text:hover,
.react-datepicker__year-text:hover {
  border-radius: 0;
  background-color: rgba(161, 172, 184, .45);
  color: var(--primary-color);
}

.react-datepicker__day--keyboard-selected,
.react-datepicker__month-text--keyboard-selected,
.react-datepicker__quarter-text--keyboard-selected,
.react-datepicker__year-text--keyboard-selected {
  border-radius: 0;
  background-color: var(--secondary-color);
  color: var(--primary-color);
}

.react-datepicker__navigation--next,
.react-datepicker__navigation--next:hover {
  border-left-color: var(--primary-color);
}

.react-datepicker__navigation--previous,
.react-datepicker__navigation--previous:hover {
  border-right-color: var(--primary-color);
}

.react-datepicker__year-read-view--down-arrow,
.react-datepicker__month-read-view--down-arrow,
.react-datepicker__month-year-read-view--down-arrow {
  border-top-color: var(--primary-color);
  margin-left: 2rem;
}

.react-datepicker__month-read-view--selected-month,
.react-datepicker__year-read-view--selected-year {
  color: var(--primary-color);
  font-weight: 400;
}

.react-datepicker__year-read-view:hover .react-datepicker__year-read-view--down-arrow,
.react-datepicker__year-read-view:hover .react-datepicker__month-read-view--down-arrow,
.react-datepicker__month-read-view:hover .react-datepicker__year-read-view--down-arrow,
.react-datepicker__month-read-view:hover .react-datepicker__month-read-view--down-arrow,
.react-datepicker__month-year-read-view:hover .react-datepicker__year-read-view--down-arrow,
.react-datepicker__month-year-read-view:hover .react-datepicker__month-read-view--down-arrow {
  border-top-color: var(--primary-color);
}

.react-datepicker__year-dropdown,
.react-datepicker__month-dropdown,
.react-datepicker__month-year-dropdown {
  background-color: #ffffff;
  border: transparent;
  border-radius: 0;
  box-shadow: 0 0.25rem 1rem rgba(161, 172, 184, .45);
  animation: dropdownAnimation .1s;
  padding: 0.5rem 1rem;
}

.react-datepicker__year-option:hover,
.react-datepicker__month-option:hover,
.react-datepicker__month-year-option:hover {
  background-color: rgba(161, 172, 184, .45);
}

.timeline .timeline-item .timeline-indicator.timeline-indicator-default {
  border: 2px solid #fec827;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type=number] {
  -moz-appearance: textfield;
}

.page-link.disabled,
.disabled>.page-link {
  color: var(--bs-pagination-disabled-color);
  pointer-events: none;
  background-color: rgba(161, 172, 184, .45);
  border-color: rgba(161, 172, 184, .45);
}

.ql-container.ql-snow {
  min-height: 100px;
  border: 1px solid #d9dee3;
}

.ql-toolbar.ql-snow {
  border: 1px solid #d9dee3;
}

.quill.error .ql-toolbar.ql-snow {
  border: 1px solid #ff3e1d;
}

.quill.error .ql-container.ql-snow {
  border: 1px solid #ff3e1d;
  border-top: 0px;
}

.quill.error {
  color: var(--bs-card-color);
}

.ql-editor.ql-blank::before {
  font-style: normal;
}

.ql-container {
  font-family: inherit;
  font-size: inherit;
}

.grid-view th,
.grid-view td,
.detail-view th,
.detail-view td {
  vertical-align: top;
}

.img-login-md {
  max-height: 80vh;
  width: auto;
  position: fixed;
  z-index: 0;
  filter: opacity(0.1);
  bottom: 0;
}

.swal2-container {
  z-index: 2000 !important;
}

li.page-item {
  display: none;
}

/* .page-item:first-child, */
.page-item:nth-child(2),
.page-item:nth-last-child(2),
.page-item:nth-child(3),
.page-item:nth-last-child(3),
.page-item:nth-child(4),
.page-item:nth-child(5),
/* .page-item:last-child, */
.page-item.active,
.page-item.disabled {
  display: block;
}


.custom-active .nav-item a {
  padding-bottom: 0.3rem !important;
}

.custom-active .nav-item.active a {
  padding-bottom: 0.3rem !important;
}

.custom-active .nav-item.active a::after {
  content: " ";
  background-color: var(--secondary-color);
  width: 1.5rem;
  height: 0.2rem;
  display: block;
}

.custom-active .nav-item a::after {
  transition: width 0.5s;
  content: " ";
  width: 0.1rem;
  height: 0.2rem;
  display: block;
}

.page-link {
  color: var(--bs-white);
}